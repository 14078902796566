import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import SEO from '../components/seo'
import PostListItem from '../components/postListingItem'

const PostListing = ({ pageContext }) => {
  const { currentPage, numPages, posts, title, basePath } = pageContext
  return (
    <React.Fragment>
      <SEO title={title} keywords={posts.map(post => post.title)} />
      <Container>
        <h1>{title}</h1>
        <ul>
          {posts.map(post => <PostListItem key={post.slug} post={post} tags={post.tags} />)}
        </ul>
      </Container>

      {currentPage > 1 && (
        <PaganationButton
          className="newer"
          to={currentPage === 2 ? basePath : `${ basePath }page/${ currentPage - 1 }`}
        >
          ← Newer Posts
        </PaganationButton>
      )}
      <Paganation>
        Page: {currentPage} of {numPages}
      </Paganation>
      {currentPage < numPages && (
        <PaganationButton className="older" to={`${ basePath }page/${ currentPage + 1 }`}>
          Older Posts →
        </PaganationButton>
      )}
    </React.Fragment>
  )
}

const Paganation = styled.span`
  font-size: .8rem;
  color: var(--primary-text-color);
`

const PaganationButton = styled(Link)`
  font-size: .7em;
  padding: 8px 12px;
  border: 1px solid var(--highlight-color-dark);
  border-radius: 20px;
  color: var(--highlight-color-dark);
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 10px 20px;
  text-decoration: none;

  &.newer {
    margin-right: 16px;
  }

  &.older {
    margin-left: 16px;
  }
`

const Container = styled.div`
  h1 {
    text-align: center;
    margin: 64px;
  }

  ul {
    list-style-type: none;
    padding 0;
  }
`

export default PostListing
