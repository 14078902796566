import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Tag from './tag'
import renderDate from './date'
import PostLink from './postLink'

const PostListItem = ({ post }) => {
  return (
    <StyledLi>
      <div>
        <PostLink to={`/${ post.slug }`}>{post.title}</PostLink>{' '}
        {post.tags && post.tags.map(tag => (
          <Tag key={tag.slug} tag={tag}>
            <Link to={`/tag/${ tag.slug }`}>{tag.name}</Link>
          </Tag>
        ))}
      </div>
      <PostDate>
        {renderDate(new Date(post.published_at))}
      </PostDate>
    </StyledLi>
  )
}

const PostDate = styled.span`
  flex: 0 0 100px;
  text-transform: uppercase;
  color: var(--secondary-text-color);
  font-size: 10px;
  letter-spacing: 2px;
}
`

const StyledLi = styled.li`
  line-height: 2.1rem;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export default PostListItem
